import {Grid, SxProps, Theme, Typography} from "@mui/material";
// import ForwardIcon from '@mui/icons-material/Forward';
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
// import AppEmployeeHeatMap from "../components/app/AppEmployeHeatMap";
// import { xaxisOptions } from "../services/lineCharts";
import {getBackgroundColorByScore, getBorderColorByScore, getColorByScore} from "../helper/colors";
import {max, mean, min} from "lodash";
import {numberFormatter} from "../helper/charts";
import * as React from "react";

export type EmployeeKPICardProps = {
  name: string;
  lineChartData: number[];
  kpi: string;
  onClick?: ((arg0: string) => void) | undefined;
  sx?: SxProps<Theme> | undefined,
  status?: number | undefined,
  disableHover?: boolean | undefined,
  backgroundColor?: string | undefined,
  pathColor? : string | undefined,
};

export default function EmployeeKPICard(props: EmployeeKPICardProps) {

  const [cardBoxShadow, setCardBoxShadow] = React.useState(3);
  const score = props.lineChartData[props.lineChartData.length - 1];
  const pathColor = props.pathColor ? props.pathColor : getColorByScore(
      props.status ? props.status : score
  )
  const backgroundColor = props.backgroundColor ? props.backgroundColor : getBackgroundColorByScore(
      props.status ? props.status : score
  )
  const scoreDelta = score - mean(props.lineChartData);
  const getScoreDeltaString = () => {
      if(scoreDelta > 0){
          return `+${numberFormatter(scoreDelta)}%`
      }
      return `${numberFormatter(scoreDelta)}%`
  }
  // const arrowRotation = -1 * (
  //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //     scoreDelta > 0 ? min([scoreDelta * 5, 70])! : max([scoreDelta * 5, -70])!
  // );
  const getScoreDeltaColor = (): string => {
      if(scoreDelta > 0){
          return getBorderColorByScore(100)
      }
      return getColorByScore(0)
  }

  let disableHover = props.disableHover;
  if(score > 75){
      disableHover = true;
  }

    return (
    <Grid
      item
      key={props.kpi}
      xs={12}
      md={4}
      lg={3.8}
      sx={{
        height: 180,
        width: 240,
        float: "left",
        background: backgroundColor,
        margin: 0.5,
        padding: 1,
        borderRadius: 1,
        boxShadow: cardBoxShadow,
        ':hover': !disableHover ? {
            cursor: "pointer"
        } : undefined,
        ...props.sx
      }}
      onMouseOver={() => !disableHover ? setCardBoxShadow(10) : null}
      onMouseOut={() => !disableHover ? setCardBoxShadow(3) : null}
      onClick={(score < 75) && (props.onClick) ? () => {
          props.onClick?.(props.kpi)
      } : undefined}
    >
      <Typography
        variant="h4"
        gutterBottom
        style={{
          color: "#0323f8",
          marginLeft: 1,
          fontWeight: 600,
          fontSize: 13,
        }}
      >
        {props.name.toUpperCase()}
      </Typography>
        {/*<ForwardIcon sx={{
           color: getScoreDeltaColor(),
            height: 100,
            width: 100,
            marginLeft: 7,
            transform: `rotate(${arrowRotation}deg)`
        }}/>
        <Typography
            variant="h4"
            style={{
                color: getScoreDeltaColor(),
                marginLeft: 80,
                fontSize: 10,
                fontWeight: 700,
                marginBottom: -20
            }}
        >
            {getScoreDeltaString()}
        </Typography>*/}

        {<Grid
          item
          xs={12}
          md={5}
          lg={8}
          sx={{
              float: "left",
              padding: 2,
              marginLeft: 4,
          }}
      >
        <CircularProgressbarWithChildren
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value={props.lineChartData.at(props.lineChartData.length - 1)!}
          // text={`${score}% \n${scoreDelta()}`}
          styles={buildStyles({
            textColor: "black",
            pathColor: pathColor,
          })}
        >
            <Typography
                variant="h3"
                style={{
                    color: "#000000",
                    fontSize: 26,
                    fontWeight: 900,
                    marginBottom: 5
                }}
            >
                {score.toFixed(0)}%
            </Typography>
            {/*<ForwardIcon sx={{
                color: getScoreDeltaColor(),
                height: 70,
                width: 70,
                marginLeft: 1,
                transform: `rotate(${arrowRotation}deg)`
            }}/>*/}
            <Typography
                variant="h4"
                style={{
                    color: getScoreDeltaColor(),
                    fontSize: 10,
                    fontWeight: 700,
                    marginBottom: -5,
                    marginTop: -5,
                }}
            >
                {getScoreDeltaString()}
            </Typography>
        </CircularProgressbarWithChildren>
      </Grid>}
        {/*<Grid item xs={12} md={4} lg={6} sx={{ float: "left" }}>
        <AppEmployeeHeatMap
          title=""
          subheader=""
          chartLabels={xaxisOptions.categories}
          height={100}
          showlabel={false}
          color={getColorByScore(score)}
          stokewidth={0.8}
          chartData={[
            {
              name: "Score",
              type: "area",
              fill: "gradient",
              data: props.lineChartData,
            },
          ]}
          other={[]}
        />
      </Grid>*/}
    </Grid>
  );
}
